body {
	font-family: $font-family-primary;
	font-size: $font-size-14;
	color: $text-color;
	line-height: 1.5;
	background-color: $body-bg;
	overflow-y: auto;
	overflow-x: hidden;
}
::selection {
  background: $primary;
  color: $white;
  text-shadow: none;
}
img {
	max-width: 100%;
	height: auto;
	vertical-align: inherit;
}
*{
	outline: none;
}
button {
	@include transition(all 0.5s ease);
	&:focus {
		box-shadow: none !important;
	}
}
a {
	color: $primary;
	cursor: pointer;
	text-decoration: none;
	@include transition(all 0.5s ease);
	&:hover {
		color: $primary-hover;
		@include transition(all 0.5s ease);
	}
	&:focus {
		outline: 0;
	}
}
p {
	margin-bottom:20px;
	&:last-child {
		margin-bottom: 0;
	}
}
strong {
	font-weight: $font-weight-bold;
}
h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  	color: $title-color;
  	font-family: $font-family-primary;
	font-weight: $font-weight-bold;
  	margin-bottom: 0;
}
ol, ul {
  list-style: none;
  margin-block-end: 0;
  padding: 0;
}
ul {
  list-style: none;
	&.list-disc {
	  list-style: disc;
	  padding-left: 15px;
	  ul {
	    list-style-type: circle;
	  }
	}
	&.list-decimal {
	  list-style: decimal;
	  padding-left: 15px;
	  ul{
	    list-style-type: decimal;
	  }
	}
	&.list-icon {
	  list-style: none;
	  padding-left: 0;
	  > li {
	    padding-left: 1.875rem;
	    counter-increment: li;
	    i,
	    .feather-icon,
	    .svg-icon {
	      display: inline-block;
	      height: 20px;
	      width: 20px;
	      text-align: center;
	      margin-left: -1.25rem;
	      position: relative;
	      left: -10px;
	      padding: 1px;
	      top: -1px;
	      
	    }
	    i {
	      font-size: $font-size-20;
	      top: 5px;
	    }
	  }
	}
	.btn {
	  &-primary {
	      &:not(:disabled):not(.disabled) {
	          &:active,
	          &.active {
	              &:focus {
	                  box-shadow: none;
	              	}
	          	}
	      	}
	  	}
	}
}

// ---------------------Star---------------------
.custom-text-blue {
	color: blue;
	transition: all .3s ease-in-out;
	&:hover {
		color: #FF9F34 !important;
	}
}

.custom-text-red {
	color: red;
}


.scroll-to-top {
	position: fixed;
	bottom: 65px;
	right: 20px;
	background-color: #007bff;
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.4s ease;
  }
  
  .scroll-to-top.visible {
	opacity: 1;
  }
  
  .scroll-to-bottom {
	position: fixed;
	bottom: 20px;
	right: 20px;
	background-color: #007bff;
	color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
	opacity: 0;
	transition: opacity 0.4s ease;
  }
  
  .scroll-to-bottom.visible {
	opacity: 1;
  }
  
  a.link-title {
    font-size: small;
    color: blue;
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}


.login-star-wrapper {
	height: 100vh;
	background: url("../../../core/img/bg-login.jpg") no-repeat;
	background-size: cover;
	display: flex;
	justify-content: center;
	align-items: center;
  }
  
  .login-star-form {
	width: 445px;
	overflow: hidden;
	background: #fff;
	border-radius: 5px;
  }
  .login-star-form-title {
	height: 200px;
	width: 100%;
	background: url("../../../core/img/bg-title.jpg") no-repeat;
	background-size: cover;
  }
  .login-star-form-body {
	padding: 48px;
	display: flex;
	flex-direction: column;
  }
  
  .login-star-signin-button {
	height: 48px;
	width: 100%;
	background-image: linear-gradient(to right, #00dbde, #fc00ff);
	transition: all 0.4s ease-in-out;
	border: none;
	outline: none;
	color: #fff;
	border-radius: 10px;
  }
  .login-star-signin-button:hover {
	background-image: linear-gradient(to right, #fc00ff, #00dbde);
  }
  .login-star-form-body h3 {
	margin-bottom: 30px;
  }
  .login-star-form-input {
	width: 100%;
	display: flex;
	align-items: center;
	margin-bottom: 20px;
	border: 1px solid rgba(0, 0, 0, 0.1);
	height: 48px;
	border-radius: 10px;
	padding: 0 15px;
  }
  .login-star-form-input input {
	outline: none;
	color: #808080;
	margin-left: 10px;
	border: none;
	padding: 7px 0;
	width: 100%;
  }
  .login-star-signup-title {
	margin-top: 16px;
	color: #80808080;
	display: flex;
	justify-content: center;
  }
  .login-star-signup-title span {
	color: #01d28e;
	cursor: pointer;
	margin-left: 5px;
  }

  
.g2-tooltip-title {
	color: #000 !important; 
	font-size: 15px !important;
	font-weight: bold !important;
  }
  .g2-tooltip-list-item {
	font-size: 15px !important;
	font-weight: bold !important;
  }
  
  .ant-drawer-header {
	background: rgb(19, 32, 47);
  }
  
  .ant-drawer-title {
	color: #fff !important;
  }

  
.ant-drawer {
	z-index: 1001 !important;
  }
  
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
	border: 1px solid #dddddd;
  }
  
  .fixed-header {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1;
	overflow-x: hidden;
	border-bottom: 1px solid #ddd; /* Optional: Add a border if needed */
	background-color: #fff; /* Optional: Add background color */
  }
  
  .table-header {
	visibility: hidden;
  }
  
.flipHorizontal {
    filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
    -webkit-transform: scale(-1, 1);
    -ms-transform: scale(-1, 1);
    transform: scale(-1, 1);
}

.modal-open .ant-select-dropdown {
	z-index: 1060;
}


.table tbody tr td .lowest-price {
	color: darkblue !important;
	font-size: 12px !important;
}

.jan-input-wrapper input {
	height: 36px;
	line-height: 1.3;
	padding: 0.5rem 0.4rem;
}

.sellermark {
	color: #fff !important;
	width: 50px;
	cursor: pointer;
	margin: 0 auto;
	background-color: #000000;
	text-align: center;
}
.sellermark.weeea2 {
	background-color: #6C8FF8;
}
.sellermark.kt\.japan, .sellermark.kt {
	background-color: #6BC66B;
}
.sellermark.njoy {
	background-color: #E0849B;
}
.sellermark.fhjiyama, .sellermark.fh {
	background-color: #B284E0;
}

.ant-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
	font-weight: bold;
}