
.section-bulk-wrap {
    .form-sort {
        position: relative;
        svg {
            &.feather-filter {
                position: absolute;
                z-index: 101;
                width: 16px;
                height: 16px;
                top: 12px;
                left: 10px;
            }
            
        }
    }
}

.page-add-notes.active svg {
    transform: rotate(0) !important;
}
.dropdown-menu {
    &.notes-menu {
        z-index: 99999;
        a {
            &.dropdown-item {
                color: $secondary;
                font-size: $font-size-15;
                font-weight: $font-weight-normal;
                display: flex;
                align-items: center; 
                padding: 10px 20px;   
                &:hover {
                    background: $warning;
                    color: $white;
                    svg {
                        color: $white;
                    }
                }                          
            }
            
        }   
        span {
            margin-right: 8px;
        }
        svg {
            width: 14px;
            height: 14px;
            color: $text-color;
        }
    } 
}
.edit-note-head {
    a {
        border-radius: 30px;
        border: 1px solid rgba(145, 158, 171, 0.30);
        background:  $white;
        color: $secondary;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 20px;
        height: 20px;
        &:hover {
            border-radius: 20px;
            background:  $primary;
            color: $white;
        }
    }
}
.custom-modal-body {
    .select2-container {
        .selection {
            .select2-selection {
                .select2-selection__rendered {
                    padding-left: 10px!important;
                }
            }
        }
        
    }
}
.bulk-con-wrap {
    width: 100%;
}
.section-bulk-widget {
    transition: 0.6s all
}
.section-notes-dashboard {
    display: none;
    transition: 0.6s all
}
.notes-tog {
    i {
        transition: 0.5s all;
    }
}
.notes-tag-left {
    .notes-tog {
        i {
            transform: rotate(360deg);
            transition: 0.5s all;
        }
    }
}
.budgeted-role-notes {
    width: 100%;
}
.notes-summernote .card{
    margin-bottom: 0 !important;
}
.dropdown-item:active {
    background: transparent;
}
.delete-popup {
    padding: 24px;
    border-radius: 8px;
    background: $white;
    .delete-image {
        margin-bottom: 20px;
    }
    .delete-heads {
        text-align: center;
        h4 {
            color: $secondary;
            font-size: $font-size-20;
            font-weight: $font-weight-bold;
            margin-bottom: 5px;
        }
        p {
            color: $text-color;
            font-size: $font-size-15;
            font-weight: $font-weight-normal;
            margin-bottom: 0px;
        }       
    }
    .delete-footer {
        text-align: center;
    }
}
.edit-page-title {
    display: flex;
    align-items: center;
    h4 {
        font-size: $font-size-18;
        font-weight: $font-weight-bold;
        color: $secondary;
        margin-bottom: 0;
        margin-right: 10px;
    }
    p {
        font-size: $font-size-15;
        font-weight: $font-weight-normal;
        color: #288EC7;
    }
}
.edit-head-view {
    margin-bottom: 20px;
    border-bottom: rgba(145, 158, 171, 0.30);
    h6 {
        font-size: $font-size-16;
        font-weight: $font-weight-semibold;
        color: $secondary;
        margin-bottom: 15px;
    }
    p {
        font-size: $font-size-15;
        font-weight: $font-weight-normal;
        color: $text-color;
        margin-bottom: 15px;
    }
    p {
        &.badged {
            i {
                font-size: 7px;
                margin-right: 7px;
            }
            border-radius: 8px;
            border: 1px solid $warning;
            padding: 4px 10px;
            margin-bottom: 0;
            color: $warning;
            font-size: $font-size-14;
            font-weight: $font-weight-semibold;
            display: inline-flex;
            align-items: center;
        }
    }
}
.edit-footer-menu a {
    background-color: $danger;
}
.edit-noted-head {
    a {
        span {
            i{
                 &.fa-trash-alt {
                    color: $danger;
                    margin-right: 10px;
                 }
                 &.fa-star {
                    color: $warning;
                 }
            }
        }
    }
}
.edit-head-view {   
    p {
        &.badged.high {
            i {
                font-size: 7px;
                margin-right: 7px;
            }
            border-radius: 8px;
            border: 1px solid $danger;
            padding: 4px 10px;
            margin-bottom: 0;
            color: $danger;
            font-size: $font-size-14;
            font-weight: $font-weight-semibold;
            display: inline-flex;
            align-items: center;
        }
    }
}