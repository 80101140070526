.fbamanage {

    .ant-table-thead>tr>th {
        border-left: 0;
        border-right: 0;
    }

    .ant-table-tbody {
        tr {
            td {
                border-left: 0;
                border-right: 0;
                border-bottom: 0;
                vertical-align: top;
            }
            td:first {
                border-left: 1px;
            }

            td.overflow-visible {
                overflow: visible !important;
            }
            
            td.actions {
                z-index: 2;
                background-color: #fff;
            }
            td.actions.ant-table-cell-row-hover {
                background-color: #fafafa;
            }

            td.ant-table-cell .ant-select-selector {
                padding: 0 2px;
            }
            td.ant-table-cell .ant-select-selector .ant-select-selection-item {
                font-size: 12px;
                padding-inline-end: 16px;
            }
            td.ant-table-cell.padding-none {
                padding: 0 !important;
            }
        }

        tr.ant-table-expanded-row {
            td.ant-table-cell {
                background-color: #fff;
                border-bottom: 0;
                border-top: 0;
                padding-top: 0 !important;
            }
        }

        div.product-titles {
            max-width: 200px;
            
            .product-title {
                max-width: 200px;
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: start;
            }
        }
        div.line1, div.line2, div.line3 {
            z-index: 2;
            position: relative;
            height: 45px;
            display: flex;
            flex-direction: row;
            align-items: center;
            text-align: center;
            justify-content: center;
        }
    }

}