.table-tab {
    ul {
        margin-bottom: 24px;

        li {
            margin-right: 10px;

            &:last-child {
                margin-right: 0;
            }

            button.nav-link {
                font-weight: $font-weight-bold;
                padding: 10px;
                line-height: normal;
                color: $secondary;
                background-color: $gray-400;

                &.active {
                    background-color: $primary;
                    color: $white;
                }
            }
        }
    }

    .nav-pills {
        @include respond-below(custom575) {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            justify-content: center;
            -webkit-justify-content: center;
            -ms-flex-pack: center;
        }
    }
}

.badge {
    &.badge-bgdanger {
        background-color: rgba(255, 0, 0, 0.06);
        color: $danger;
        padding: 5px 10px;
    }
}

button.swal2-styled.swal2-confirm {
    background-color: $primary;

    &:hover {
        background-color: $secondary
    }
}

table {
    tbody {
        td {
            .action-table-data {
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                flex-direction: column;
                padding: 0 !important;

                a {
                    height: 28px;
                    width: 28px;
                    border: 1px solid rgba(145, 158, 171, 0.30);
                    background-color: $white;
                    border-radius: 5px;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    padding: 6px;

                    .feather-edit {
                        color: $blue;
                        width: 14px;
                        height: 14px;
                    }

                    .action-edit {
                        color: $blue;
                        width: 14px;
                        height: 14px;
                    }
                    
                    .action-trash {
                        color: $danger;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-trash-2 {
                        color: $danger;
                        width: 14px;
                        height: 14px;
                    }

                    .feather-eye {
                        width: 14px;
                        height: 14px;
                    }

                    .action-download {
                        color: $blue;
                        width: 14px;
                        height: 14px;

                    }

                    .action-eye {
                        color: $secondary;
                        width: 14px;
                        height: 14px;
                    }
                }

            }
        }

        .productimgname {
            min-width: 200px;

            a {
                &.stock-img.product-img {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    margin-right: 8px;

                    img {
                        width: 50px;
                        height: 50px;
                        max-width: 100%;
                        margin-right: 0;
                    }
                }
            }
        }

        .inventoryimgname {
            max-width: 300px;

            a {
                &.stock-img.product-img {
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    align-items: center;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    justify-content: center;
                    -webkit-justify-content: center;
                    -ms-flex-pack: center;
                    margin-right: 8px;

                    img {
                        width: 80px;
                        height: 80px;
                        max-width: 100%;
                        margin-right: 0;
                    }
                }

                &.product-title {
                    max-width: 200px;
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: start;
                }

                &.link-title {
                    font-size: small;
                    color: blue;
                    width: 100%;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    overflow: hidden;
                    text-align: center;
                }


            }

            .category-title {
                font-size: small;
                color: rgb(141, 141, 226);
                width: 100%;
                text-overflow: ellipsis;
                white-space: nowrap;
                overflow: hidden;
                text-align: center;
            }


        }
    }

    .edit-delete-action {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        justify-content: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        height: 36px;

        a {
            padding: 0;
        }

    }

    .edit-delete-action.data-view {
        min-height: 52px;
    }

    .edit-delete-action.data-row {
        height: auto;
    }

    .edit-delete-action.data {
        height: 44px;
    }
}

.seller-title {
    font-size: medium;
    color: rgb(11, 11, 12);
    width: 100%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
}

.custom-modal-header {
    background: $body-bg;
    padding: 24px;

    .page-title {
        h4 {
            font-size: $font-size-18;
            font-weight: $font-weight-bold;
            color: $secondary;
        }
    }
}

.custom-modal-body {
    padding: 24px;

    .input-blocks {
        margin-right: 0;
    }

    label {
        font-size: $font-size-15;
        font-weight: $font-weight-normal;
    }
}

.status-label {
    color: #212B36;
}

.modal-footer-btn {
    margin-top: 40px;
    text-align: right;

    a {
        min-width: auto;

        @include respond-below(custom575) {
            padding: 10px 10px;
        }
    }
}

.status-toggle.modal-status label {
    height: 15px;
    width: 32px;
    background-color: #B9B9C3;
    margin-bottom: 0;
}

.status-toggle.modal-status label::after {
    top: 7px;
}

td {
    p {
        &.description-para {
            max-width: 400px;
            white-space: break-spaces;
        }
    }
}

.form-sort {
    .select2-container--default .select2-selection--single .select2-selection__rendered {
        padding-left: 35px;
    }

    span.select2-container.select2-container--default.select2-container--open {
        z-index: 99;
    }
}

.summer-description-box {
    .card .card-header {
        padding: 8px;
        border-bottom: 1px solid rgba(145, 158, 171, 0.32);
    }

    .note-editor {
        border: 1px solid rgba(145, 158, 171, 0.32);
    }

    .note-editable {
        height: 150px !important;
    }

    .note-resizebar {
        display: none;
    }
}

.table tbody {
    td {
        a {
            &.view-note {
                color: $blue;
                font-weight: $font-weight-bold;
            }
        }
    }
}

.userimgname {
    .product-img {
        display: inherit;
        width: 35px;
        height: 35px;
        margin-right: 8px;
    }
}

.modal-body-table {
    background-color: #F6F7F8;
    padding: 24px;
    margin-bottom: 20px;
    border-radius: 8px;
}


.input-blocks.search-form {
    input {
        padding-left: 30px;
    }

    svg {
        position: absolute;
        left: 10px;
        top: 37px;
        width: 15px;
    }
}

.badge badge-linedanger {
    background-color: rgba(255, 0, 0, 0.06);
    color: $danger;
    border-radius: 8px;
    padding: 4px 10px;
    font-size: $font-size-base;
}

.input-groupicon.calender-input input {
    padding-left: 27px;
}

span {
    &.customer-toggle {
        color: $secondary;
    }
}

span {
    &.unlimited-text {
        color: $text-color;
        margin-top: 5px;
        display: inline-block;
    }
}

.modal-title-head {
    h6 {
        font-size: $font-size-16;
        display: flex;
        align-items: center;
        margin-bottom: 20px;

        svg {
            width: 15px;
            color: $primary;
            margin-right: 5px;
        }
    }
}

#phone,
#phone2,
#phone3 {
    padding-left: 50px;
}

.layout-box {
    width: 36px;
    height: 36px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    justify-content: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    background: $primary;
    color: $white;
    border-radius: 8px;

    &:hover {
        background-color: $secondary;
        color: $white;
    }
}

td {
    span {
        &.emp-team {
            display: block;
            line-height: normal;
        }
    }
}

.badges-inactive {
    border: 1px solid $danger;
    color: $danger;
    padding: 2px 10px;
    font-size: $font-size-14;
    font-weight: $font-weight-semibold;
    border-radius: 8px;
}

.table-top {
    &.table-top-new {
        @include respond-below(custom767) {
            flex-direction: column;
            align-items: self-start;
        }

        .search-path {
            margin-left: 0;

            @include respond-below(custom767) {
                margin-top: 24px;
            }

            @include respond-below(custom575) {
                flex-direction: column;
                align-items: self-start;

            }

            .form-sort {
                @include respond-below(custom575) {
                    margin-top: 20px;
                }
            }
        }

        .search-set {
            @include respond-below(custom767) {
                flex-direction: column;
                align-items: flex-start;

                .total-employees {
                    margin: 0 0 20px 0;
                }
            }
        }
    }
}

.badge-applied {
    background-color: $purple;
    padding: 2px 10px;
    color: $white;
    border-radius: 5px;
}

.badge-approved {
    background-color: $success;
    padding: 2px 10px;
    color: $white;
    border-radius: 5px;
}

.badge-reject {
    background-color: $danger;
    padding: 2px 10px;
    color: $white;
    border-radius: 5px;
}

.table tbody tr td a.reason-for-reject {
    color: $blue;
    font-weight: $font-weight-semibold;
}

.note-editor.note-frame.card {
    margin-bottom: 0;
}

table tbody td.action-table-data a.cancel-circle {
    border: 0;
    padding: 0;

    svg {
        color: $primary;
    }

    &:hover {
        background-color: transparent;

        svg {
            color: $danger !important;
        }
    }
}

.apply-leave {
    background-color: $body-bg;
    border-radius: 8px;

    .leave-apply {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        padding: 10px;

        @include respond-below(custom575) {
            flex-direction: column;
            align-items: flex-start;
        }

        .leave-date {
            margin-right: 70px;

            span {
                color: $text-color;
            }

            p {
                color: $secondary;
            }

            @include respond-below(custom575) {
                margin-right: 0;
                margin-bottom: 20px;
            }
        }

        .leave-time {
            width: 175px;
        }
    }
}

.input-blocks {
    .bootstrap-tagsinput {
        width: 100%;
        padding: 0 .75rem;
        box-shadow: none;
        line-height: 40px;

        .tag {
            background-color: $gray-300;
            color: $secondary;
            padding: 3px 10px;
            border-radius: 4px;
            position: relative;
            z-index: 1;

            .remove {
                &::after {
                    color: $text-color;
                }
            }
        }

        input {
            position: static;
            width: auto;
            border: none;
        }
    }

    span.tag-text {
        color: $text-color;
        font-size: $font-size-13;
    }
}

.table tbody tr td .sales-dropdown-list a {
    padding: 10px 20px;
}

.no-pagination {
    .pagination {
        display: none;
    }
}

#collapse-header.active {
    background-color: $primary;
    border-color: unset;

    svg {
        color: $white;
        transform: rotate(180deg);
        -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
    }

    &:hover {
        svg {
            color: $white;
        }
    }
}

.edit-delete-action {
    a {
        &:hover {
            background-color: rgb(40, 142, 199);
            border-color: rgb(40, 142, 199);

            svg {
                color: $white !important;
            }
        }

        &.confirm-text {
            &:hover {
                background-color: $danger;
                border-color: $danger;
            }
        }

        &.edit-icon {
            &:hover {
                background-color: $secondary;
                border-color: $secondary;
            }
        }
    }
}

.modal {
    table.table {
        thead>tr>th.sorting:before {
            display: none !important;
        }

        thead>tr>th.sorting:after {
            display: none !important;
        }
    }
}
