.delivery-logs {
    .ant-table-tbody {
        tr {
            td.ant-table-cell a,
            td.ant-table-cell div {
                font-size: 13px !important;
            }
        }
    }

}