.page-wrapper{
    .page-header{
        .back-btn{
            display: flex;
            gap: 10px;
        .page-display{
            a{
                padding: 7px;
                background-color: $secondary;
                color: $white;
                display: flex;
                align-items: center;
                gap: 8px;
                border-radius: 8px;
            }         
        }
    }
    }
}

.input-block input.form-control.list {
    color: #878A99;
}

.nav-pills.list .nav-link.active{
    background-color: transparent;
    color: $text-color;
}
.nav-pills.list .nav-link{
    background-color: transparent;
    color: $text-color;
}
.add-icon.tab a{
    background-color: $secondary;
    color: $white;
}
.nav-pills.list .nav-item button{
    position: relative;
}
.nav-pills.list .nav-item button::before {
    content: "";
    width: 15px;
    height: 15px;
    border: 1px solid #E0E0E0;
    border-radius: 50%;
    position: absolute;
    top: 11px;
    left: 12px;
}
.nav-pills.list .nav-item button.active::after {
    content: "";
    width: 7px;
    height: 7px;
    background: $primary;
    border-radius: 50%;
    position: absolute;
    top: 15px;
    left: 16px;
}
.nav-pills.list .nav-item button.active::before {
    border-color:$primary;
}
.add-icon.tab-icon .feather-trashs{
    background-color: $white;
    color: $danger;
}
.add-icon.tab-icon a{
    background-color: $white;
    font-size: 11px;
    width: 34px;
    padding: 0;
}