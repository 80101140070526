
.menu-opened{
    .pos-page{
        &.slide-nav{
            .sidebar{
                margin-left: 0;
            }    
        }
        .sidebar{
            margin-left: -100%;
        }    
    }
}

.table{
    tr{
        &:hover{
            .productimg{
                .productcontet{
                    h4{
                        img{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.card{
    &.card-order{
        border: 0;
    }
}